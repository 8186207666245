.invoiceupload {
  margin-bottom: 5px;
}

#invoicemessage {
  padding: 0.375rem 0.75rem;
}

.documentupload {
  margin-bottom: 5px;
}

.uploadprogressbars {
  margin-bottom: 5px;
}

.multiupload {
  margin-bottom: 10px;
}
